export default {
  title: "Позиции чеков",
  singularTitle: "Позиция чека",
  sortBy: "pos",
  static: true,
  primaryKey: "pos",
  permissions: {
    view: false,
    viewAny: false,
    create: true,
    update: true,
    delete: true,
  },

  fields: [
    { id: "pos", label: "#", component: "primary", hint: "Номер позиции" },
    { id: "title", label: "Заголовок", sortable: false },
    { id: "qty", label: "Кол-во", sortable: false, default: 1 },
    { id: "sum", label: "Сумма", component: "numeric", sortable: false, default: 0 },
    {
      id: "product_id",
      label: "Товар",
      component: "belongs-to",
      resourceName: "products",
      relation: "product",
      staticFilter: {
        type: "promotional",
        is_active: true,
      },
    },
  ],
};
